import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {PoliciesContainer} from "../../components/policies/container";
import {PoliciesMDWrapper} from "../../components/policies/md-wrapper";
import {useEffect, useState} from "react";
import {MarkdownViewer} from "../../components/markdown-viewer";
// https://commonmark.org/help/ markdown reference
// https://stackedit.io/app# for editing

// cookie-consent.md

export function handleSetMD(md) {
    return md
        .replace('(./images/','(https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Fimages%2F')
        .replace('.png)', '.png?alt=media)')
        .replace('.jpg)', '.jpg?alt=media)')
        .replace('.jpeg)', '.jpeg?alt=media)')
}

export default function UnatyCommunityTermsAndConditionsForCustomers() {
    const [data,setData] = useState(null);
    useEffect(function () {
        // https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Fcookie-consent.md?alt=media
        fetch("https://firebasestorage.googleapis.com/v0/b/unaty-prod.appspot.com/o/policies%2Fcurrent%2Funaty-community-terms-and-conditions-for-customers.md?alt=media")
            .then(response => response.text())
            .then((dt) => {
                // jsonData is parsed json object received from url
                if(typeof dt === 'string') {
                    //
                    setData(handleSetMD(dt));
                }

            })
            .catch((error) => {

            })
    }, []);

    const title = 'Community Terms and Conditions for Customers';
    return (
        <Page menu title={title}>
            <Header title={title}/>
            <PoliciesContainer>
                {data&&<PoliciesMDWrapper>
                    <MarkdownViewer source={data}></MarkdownViewer>
                </PoliciesMDWrapper>}
            </PoliciesContainer>
        </Page>
    )
};